import Navbar from "./components/Navbar";
import Home from "./components/Home"
function App() {
  return (
    <div >
      <Navbar />
      <Home />
    </div>
  );
}

export default App;
